.header {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: white;
  padding: 46px 46px 0;
}

.logo {
  height: 34px;
  pointer-events: none;
}

.nav {
  display: flex;
  font-size: 15px;
  text-transform: uppercase;
}

.nav-item {
  padding: 0 14px;
  transition: color .3s cubic-bezier(.7, 0, .3, 1);
  color: #fff;
  letter-spacing: 1px;
  cursor: pointer;
}

.nav-item:after {
  transition: all .3s cubic-bezier(.7, 0, .3, 1);
  opacity: 0;
  transform: translateY(100%);
  position: relative;
  height: 5px;
  background: #fff;
  display: block;
  content: '';
  margin-top: 3px;
}

.nav-item.active:after,
.nav-item:hover:after {
  transform: translateY(0);
  opacity: 1;
}

.nav-item.active:first-child::after {
  display: none;
}

.nav-item.active .nav-progress-wrap {
  display: block;
}

.nav-progress-wrap {
  display: none;
  transition: all .3s cubic-bezier(.7, 0, .3, 1);
  transform: translateY(100%);
  height: 5px;
  background-color: #b3b3b3;
  margin-top: -2px;
  overflow: hidden;
}

.nav-progress {
  width: 0;
  height: 100%;
  background-color: #fff;
}

.toggle-button {
  transition: all .5s cubic-bezier(.7, 0, .3, 1);
  width: 34px;
  height: 34px;
  z-index: 10;
  cursor: pointer;

  &.toggle {
    transform: rotate(90deg);

    & .lines {
      background-color: transparent !important;

      &:before {
        top: 0;
        transform: translateY(0)rotate(-45deg)
      }

      &:after {
        top: 0;
        transform: translateY(0)rotate(45deg)
      }
    }
  }

  & .lines {
    transition: all .3s cubic-bezier(.7, 0, .3, 1);
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #fff;
    display: block;
    top: 50%;
    margin-top: -2px;

    &:before,
    &:after {
      transition: all .3s cubic-bezier(.7, 0, .3, 1);
      position: absolute;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #fff;
      display: block;
      content: ''
    }

    &::before {
      top: -10px
    }

    &:after {
      top: 10px
    }
  }
}

.toggle-button:hover .lines:before {
  transform: translateY(-2px)rotate(0)
}

.toggle-button:hover .lines:after {
  transform: translateY(2px)rotate(0)
}

.toggle-button.toggle:hover .lines:before {
  transform: translateY(0)rotate(-55deg)
}

.toggle-button.toggle:hover .lines:after {
  transform: translateY(0)rotate(55deg)
}

@media (max-width: 991px) {
  .nav {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 30px;
  }

  .nav {
    display: none;
  }

  .logo {
    width: 28px;
    height: 28px;
  }
}