body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.app {
  height: 100%;
  overflow: hidden;
}

.side-awards {
  width: 90px;
  height: 135px;
  position: absolute;
  top: 90px;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(assets/awwwards-ribbon.png);
  z-index: 9999;
  display: block
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.scene {
  z-index: -1;
  transition: all 0.1s ease 1s;

  &.active {
    z-index: 1;
  }
}

@media (max-width: 767px) {
  .container {
    padding-top: 90px;
    padding-bottom: 20px
  }

  body,
  html {
    font-size: 12px
  }

  .side-awards {
    width: 60px;
    height: 105px;
    top: 60px
  }
}

@keyframes slideUp {
  from {
    top: 100%;
  }

  to {
    top: 0;
  }
}

@keyframes slideDown {
  from {
    top: -100%;
  }

  to {
    top: 0;
  }
}